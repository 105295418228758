#error-message
  font-size: 16px
  text-align: left
  font-family: Helvetica, sans-serif
  color: #661d1d
  background-color: #ffeded
  border-radius: 3px
  border-color: #ff4949
  max-width: 540px
#success-message
  font-size: 16px
  text-align: left
  font-family: Helvetica, sans-serif
  color: #085229
  background-color: #e7faf0
  border-radius: 3px
  border-color: #13ce66
  max-width: 540px
#sib-container
  text-align: center
  background-color: transparent !important
  max-width: 540px
  border-radius: 0px
  border-width: 0px
  direction: ltr
  padding: 0 !important
.sib-form
  .entry__specification
    color: inherit !important
  .entry__field
    color: $dark
  .entry__label
    font-size: 19px
    font-weight: 200 !important
    text-transform: uppercase
  .entry__choice
    color: inherit !important
  .sib-form-block__button
    background: $brown
    color: white
    transition: text-shadow .7s
    &:hover
      text-shadow: 0 0 15px #ffffff
  .sib-form__declaration a,
  .entry__choice a
    color: $mint !important
  .entry__error
    font-size: 14px
    text-align: left
    font-family: Helvetica, sans-serif
    color: #661d1d
    background-color: #ffeded
    border-radius: 3px
    border-color: #ff4949
    padding: 3px 5px
    box-sizing: border-box
    display: none