body
  color: white
  background-color: $brown
  font-size: 10px
  overflow: hidden !important
#aski
  color: transparent
  font-size: 3rem
  font-family: 'barber-fill'
  text-shadow: 0 0 15px #ffffff
  display: block
  text-decoration: none
  transition: color 0.3s
  &:hover
    color: #ffffff
#wrapper
  display: flex
  align-items: center
  justify-content: center
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  z-index: 1000
  overflow: hidden !important
#popup
  .modal-content
    background: $flesh !important
    color: $brown !important